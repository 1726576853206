
















































































































































































































































































































































@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
.body.body--meter {
  overflow-y: auto;
}
.meterpage{
	padding: 16px 10px;
	margin-bottom: 36px;
	background: #30303C;
	border-radius: 2px;
}
.dataItem{
	padding: 16px 0;
	height: 156px;
	background: #23232D;
	border-radius: 2px;
}
.meter_title{
	/* width: 234px; */
	height: 27px;
	font-family: PingFangSC-Semibold;
	font-size: 16px;
	color: #E2E2E2;
	line-height: 27px;
	font-weight: 600;
}
.meter_body{
	padding: 16px 0;
}

.data_name{
	float: left;
	padding-left: 5px;
	width: 135px;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 32px;
	font-weight: 400;
}
.meter-data{
	text-align: center;
	float: left;
	width: calc(100% - 140px);;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #E2E2E2;
	line-height: 32px;
	font-weight: 400;
}

.data_name_short{
	float: left;
	padding-left: 5px;
	width: 100px;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 32px;
	font-weight: 400;
}
.meter-data_short{
	text-align: center;
	float: left;
	width: calc(100% - 125px);;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #E2E2E2;
	line-height: 32px;
	font-weight: 400;
}
